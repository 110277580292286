'use client'

import { toast } from '@/app/_primitives/Toast'
import { subscribe } from '@/backend/apis/subscriber/SubscribeAPI'
// import Image from 'next/image'
import { useCurrentLocale, useI18n } from '@/locales/client'
import { twMerge } from 'tailwind-merge'
import EmailBox from './EmailBox'
// import newsLetter from './newsLetter.svg'
import NotiPlayer from './_components/NotiPlayer'

export default function NewsLetterSection() {
  const t = useI18n()
  const currentLang = useCurrentLocale()
  const handleEmail = async (email: string) => {
    const promise = subscribe({ email }).then((it) => {
      if (it.status === 'ok') {
        return it.data
      }
      return Promise.reject(it.error)
    })
    await toast.promise(
      promise,
      {
        loading: '구독 신청중...',
        success: {
          title: '구독 신청 완료',
          content: '최신 캐릭터 소식을 보내드릴게요!',
        },
        error: (err) => err,
      },
      {
        customIcon: '🗞️',
      },
    )
  }
  return (
    <div className="relative bg-butterscotch">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-15 max-lg:py-46  lg:gap-69 2xl:flex-row 2xl:justify-end">
          <div className="w-full lg:relative ">
            {/* <Image src={newsLetter} alt="img" /> */}
            <NotiPlayer />
          </div>
          <div className="flex w-full flex-col">
            <div
              className={twMerge(
                'b-h3 whitespace-pre-line text-french-bleu lg:b-h2 ',
                currentLang === 'ja' ? '!b-h5 lg:!b-h4' : '',
              )}>
              {`${t('common.mainSec7Title1')}\n${t('common.mainSec7Title2')}`}
            </div>
            <div
              className={twMerge(
                'c-b2 whitespace-pre-line text-french-bleu mt-18 mb-24',
                currentLang === 'ja' ? '!c-b3' : '',
              )}>
              {`${t('common.mainSec7Desc1')}\n${t('common.mainSec7Desc2')}`}
            </div>
            <div className="lg:h-77 lg:w-609">
              <EmailBox
                variant="lg"
                handleEmail={handleEmail}
                className="hidden xl:flex"
              />
              <EmailBox
                variant="sm"
                handleEmail={handleEmail}
                className="flex flex-col gap-y-8 sm:flex-row sm:gap-y-0 lg:hidden"
              />
              <EmailBox
                handleEmail={handleEmail}
                className="hidden lg:flex xl:hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
