'use client'

import { Player } from '@lottiefiles/react-lottie-player'
import lottiefile from './newsLetter.json'

export default function NotiPlayer() {
  return (
    <Player
      autoplay
      controls
      loop
      src={lottiefile}
      className="min-h-300 min-w-300 lg:h-612 lg:w-672 xl:h-612"
    />
  )
}
