'use client'

import { Button } from '@/app/_primitives/Button'
import { TextField } from '@/app/_primitives/TextField'
import { cn } from '@/app/_primitives/utils/cn'
import { useI18n } from '@/locales/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export default function EmailBox({
  handleEmail,
  variant = 'default',
  className,
}: {
  handleEmail: (email: string) => unknown
  variant?: 'lg' | 'default' | 'sm'
  className?: string
}) {
  const t = useI18n()
  const form = useForm<{ email: string }>({
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, '이메일을 입력해주세요.')
          .email('이메일 형식을 입력해주세요.'),
      }),
    ),
    defaultValues: {
      email: '',
    },
    mode: 'all',
  })
  const inputCN = cn(
    `c-b6 h-48 w-full bg-white pl-40 !rounded-100 outline-none xl:c-b3`,
    {
      'h-60': variant === 'lg',
      'h-42': variant === 'sm',
    },
  )

  return (
    <form
      className={cn(`relative gap-6`, className)}
      onSubmit={form.handleSubmit((data) => {
        if (Boolean(form.formState.errors.email)) return
        handleEmail(data.email)
      })}>
      <div className="relative w-full max-lg:max-w-435">
        <TextField
          containerClassName={`w-full !rounded-100 overflow-hidden`}
          className={inputCN}
          size="lg"
          type="email"
          error={Boolean(form.formState.errors.email)}
          errorMsgs={form.formState.errors.email?.message}
          placeholder="boothbuddies@gmail.com"
          {...form.register('email')}
        />
      </div>
      <Button
        type="submit"
        size={variant === 'lg' ? 'default' : variant === 'sm' ? 'xs' : 'sm'}
        color="frenchBlue"
        className="flex w-full items-center justify-center whitespace-nowrap sm:w-156">
        {t('common.applyBtnLabel')}
      </Button>
    </form>
  )
}
